<template>
  <div class="commodityHl">
    <div v-if="shopdata" class="bg">
      <p class="bg-name">商品护理</p>
      <ul class="gk-xx">
        <li>顾客姓名</li>
        <li>{{shopdata.CustomerFull.Customer.CustomerName}}</li>
        <li>手机号码</li>
        <li>{{shopdata.CustomerFull.Customer.CustomerTel}}</li>
        <li>订单编号</li>
        <li>{{shopdata.COrderId.substring(0,8).toUpperCase()}}</li>
      </ul>
      <p style="padding: 6px 0;border-top: 1px solid #5a5959;">框架眼镜检查</p>
      <ul class="jpjc">
        <li>镜片检查</li>
        <li style="display: block;">镜片完好、镜片划伤、膜层脱落、镜片破损、自定义填写</li>
        <li>镜框检查</li>
        <li>镜框完好、镜框变形、镜腿变形、鼻托脱落、螺丝松动、自定义填写</li>
      </ul>
      <ul class="hlqj">
        <li>护理清洁</li>
        <li>清洁镜片、清洁镜框、清洁鼻托、自定义填写</li>
      </ul>
      <ul class="hlqj">
        <li>调整护理</li>
        <li>调整矫正鼻托、调整矫正镜框、调整矫正镜腿、调整矫正桩头、自定义填写</li>
      </ul>
      <ul class="hlqj">
        <li>处理意见</li>
        <li>清洁镜片、清洁镜框、清洁鼻托、自定义填写</li>
      </ul>
      <ul class="sphl-last">
        <li @click="$alert('签名成功', '提示')">顾客签名</li>
        <li @click="$alert('获取电子并签名成功', '提示')">视光师签名</li>
        <li @click="$alert('提交成功', '提示')">提交</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "commodityHl",
  data() {
    return {
      shopdata: ''
    }
  },
  created() {
    this._api.publicApi.commodityJf(this.$store.state.bases.orid)
        .then(res => {
          if (res.GetResult) {
            this.shopdata = JSON.parse(JSON.stringify(res.GetResult))
          }
        })
  },
}
</script>

<style scoped lang="scss">
$b-top: 1px solid #5a5959;
li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.commodityHl {
  width: 100vw;
  height: 100%;
  //overflow: hidden;
  overflow: auto;
}
.top {
  padding-top: 10px;
}
.bg {
  width: 900px;
  margin: 30px auto;
  border: 1px solid #5a5959;
}
.bg-name {
  width: 100%;
  text-align: center;
  padding: 10px;
}
.gk-xx {
  display: flex;
  align-items: center;
  li{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-right: 1px solid #5a5959;
    border-top: 1px solid #5a5959;
  }
  li:nth-child(1) {width: 100px;}
  li:nth-child(2) {width: 200px;}
  li:nth-child(3) {width: 100px}
  li:nth-child(4) {width: 200px;}
  li:nth-child(5) {width: 100px;}
  li:nth-child(6) {width: 200px;border-right: none;}
}
.cfsj {
  display: flex;
  align-items: center;
  justify-content: start;
  border-top: $b-top;
  li {
    height: 30px;
    border-right: $b-top;
    width: 99px;
  }
}
.sp {
  display: flex;
  li {
    border-top: $b-top;
    border-right: $b-top;
    width: 82px;
    height: 25px;
  }
  li:nth-child(1) {width: 400px;}
  li:nth-child(7) {border-right: none;width: 84px}
}
.lc {
  display: flex;
  li {
    height: 30px;
    border-top: $b-top;
    border-right: $b-top;
  }
  li:nth-child(1) {width: 566px;}
  li:nth-child(2) {width: 333px;border-right: none;}
}
.d3bg {
  display: flex;
  li{
    border-top: $b-top;
    border-right: $b-top;
    height: 30px;
    width: 224px;
    p {width: 112px;height: 30px;line-height: 30px;}
    p:nth-child(1) {border-right: $b-top;}
  }
  li:nth-child(4) {border-right: none; width: 225px;}
}
.zysx {
  padding: 10px;
  border-top: $b-top;
  text-align: left;
}
.jfsj {
  display: flex;
  li{
    border-top: $b-top;
    border-right: $b-top;
    height: 30px;
  }
  li:nth-child(1) {width: 250px}
  li:nth-child(2) {width: 150px}
  li:nth-child(3) {width: 150px}
  li:nth-child(4) {width: 250px}
  li:nth-child(5) {width: 100px; border-right: none;}
}


.sphl {
  display: flex;
  li{
    border-top: $b-top;
    border-right: $b-top;
    height: 30px;
  }
  li:nth-child(1) {width: 200px;}
  li:nth-child(2) {width: 200px;}
  li:nth-child(3) {width: 200px;}
  li:nth-child(4) {width: 200px;}
  li:nth-child(5) {width: 100px;border-right: none;}
}
.jpjc {
  display: flex;
  li {
    border-top: $b-top;
    border-right: $b-top;
    height: 50px;
  }
  li:nth-child(1) {width: 100px;}
  li:nth-child(2) {width: 350px;}
  li:nth-child(3) {width: 100px;}
  li:nth-child(4) {width: 350px; border-right: none;}
}
.hlqj {
  display: flex;
  border-top: $b-top;
  li {
    height: 30px;
    border-right: $b-top;
  }
  li:nth-child(1) {width: 100px;}
  li:nth-child(2) {width: 800px;border-right: none;}
}
.sphl-last {
  display: flex;
  border-top: $b-top;
  li {
    height: 30px;
    border-right: $b-top;
  }
  li:nth-child(1) {width: 350px;}
  li:nth-child(2) {width: 350px;}
  li:nth-child(3) {width: 200px;border-right: none;}
}

.fcys {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  li {
    width: 100px;
    text-align: center;
  }
}
</style>
